import { css, Global } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import * as React from 'react'

const ButtonStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-link-button[color='primary'],
      .leshen-phone-cta[color='primary'] {
        color: black !important;
      }
    `}
  />
))

export default ButtonStyles
